h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
	@include font-family(base);
}

h3,
.h3 {
	font-weight: 700;
}

h4, h5, h6,
.h4, .h5, .h6 {
	font-weight: 600;
}